<template>
  <div class="uk-container">
    <notifications group="public" position="bottom center"  :style="{marginBottom:'2px'}"/>
    <v-row class="uk-container">
      <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
        <base-arrow-back-icon @click="$router.go(-1)">{{
          rightArrowIcon
        }}</base-arrow-back-icon>

        <span v-if="isUpdatePage"> تعديل دورة </span>
        <span v-if="isPreviewPage"> عرض دورة </span>
        <span v-if="isNewPage"> إضافة دورة جديدة</span>
      </h1>
    </v-row>
    <v-row class="uk-container uk-margin-large-top">
      <v-form ref="form" lazy-validation>
        <v-container class="grey lighten-5">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="data.title"
                class="pa-2"
                label="العنوان"
                :rules="[(v) => !!v || 'العنوان مطلوب']"
                :readonly="isPreviewPage"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <form id="course">
                <v-file-input
                  class="pa-2"
                  label="الصورة"
                  accept="image/*"
                  name="thumbnail"
                  id="thumbnail"
                  v-if="!isUpdatePage && !isPreviewPage"
                  :rules="[(v) => !!v || 'الملخص مطلوب']"
                ></v-file-input>
              </form>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                item-text="category_title"
                item-value="category_id"
                v-model="data.category_id"
                class="pa-2"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'الصنف مطلوب']"
                :items="categories"
                label="الصنف"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="data.type"
                class="pa-2"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'النوع مطلوب']"
                :items="[
                  { text: 'Online', value: 'online' },
                  { text: 'Offline', value: 'offline' },
                ]"
                label="النوع"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="data.description"
                class="pa-2"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'الشرح مطلوب']"
                label="الشرح"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea
                v-model="data.requirements"
                class="pa-2"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'المتطلبات مطلوبة']"
                label="المتطلبات"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea
                v-model="data.targets"
                class="pa-2"
                :readonly="isPreviewPage"
                :rules="[(v) => !!v || 'الأهداف مطلوبة']"
                label="الأهداف"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <div class="text-center" v-if="!isPreviewPage">
          <v-btn color="success" class="mr-4" @click="submit">
            <span v-if="isUpdatePage">تحديث</span>
            <span v-else>إدخال</span>
          </v-btn>
        </div>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";

export default {
  data() {
    return {
      id: "",
      data: {
        title: "",
        // thumbnail: "",
        category_id: "",
        type: "",
        description: "",
        requirements: "",
        targets: "",
        language: "ar",
      },
    };
  },
  components: { BaseArrowBackIcon },
  watch: {
    singleCourse: function () {
      this.data = this.singleCourse;
      this.data.category_id = this.singleCourse.category.id;
    },
  },
  computed: {
    ...mapGetters({
      categories: "getCategories",
      singleCourse: "getCourse",
    }),
    isUpdatePage() {
      return /update\/[0-9]/.test(this.$route.path);
    },
    isPreviewPage() {
      return /show\/[0-9]/.test(this.$route.path);
    },
    isNewPage() {
      return /new/.test(this.$route.path);
    },
  },
  methods: {
    ...mapActions([
      "fetchCategories",
      "addNewCourseInstructor",
      "fetchSingleCourses",
      "updateCourseInstructor",
    ]),
    submit() {
      if (!this.$refs.form.validate()) return;
      let formData = new FormData(document.getElementById("course"));
      for (let key in this.data) {
        formData.append(key, this.data[key]);
      }
      console.log("from component", formData);
      if (this.isUpdatePage) {
        formData.append("course_id", this.id);
        formData.delete("createdAt");
        formData.delete("id");
        formData.delete("thumbnail");
        formData.delete("price");
        formData.delete("status");
        formData.delete("category");
        formData.delete("offlineCourses");
        this.updateCourseInstructor(formData).then((res) => {
          if(res?.data?.status)
            if (res.data.status === 'success' || res.data.status === 'Success' || res.data.status === 'Successe') {
              this.$router.push({ name: "coursesIndex" });
            }
        });
      } else {
        this.addNewCourseInstructor(formData).then((res) => {
          if(res?.data?.status)
            if (res.data.status === 'success' || res.data.status === 'Success' || res.data.status === 'Successe') {
              this.$router.push({ name: "coursesIndex" });
            }
        });
      }
    },
  },
  mounted() {
    this.fetchCategories();
    if (this.isUpdatePage || this.isPreviewPage) {
      this.id = this.$route.params.id;
      console.log("update");
      this.fetchSingleCourses(this.id).then((res) => {
        console.log(res)
      });
    }
  },
};
</script>